import React from "react";
import "../../src/styles/testimonial.css";
import Slider from "react-slick";

import ava01 from "../../src/images/av1-min.jpg";
import ava02 from "../../src/images/av3-min.jpg";
import ava03 from "../../src/images/av2-min.jpg";


const Testimonial = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay:true,
        autoplaySpeed:2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true
      };

  return (
    <section>
      <div className="container">
        <div className="slider__content-top">
            <h6 className="subtitle">Testimonials</h6>
            <h2>With the unwavering loyalty of over 2,000 satisfied customers,<span className="highlight"> we have earned a reputation for being a reliable and trustworthy brand.</span></h2>
        </div>

        <div className="slider__wrapper">
          <Slider {...settings}>
            <div className="slider__item">
              <p className="description">
              "I was really struggling to get positive reviews on Google Maps, and it was really affecting my business. But then I found this Maps review service, and they totally changed everything for me. Within just a few weeks, I saw a huge increase in positive reviews, and now I'm getting more customers than ever before. I would highly recommend this service to any business owner looking to improve their online reputation".
              </p>
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava01} alt="imagecustomer" />
                </div>

                <div>
                  <h5 className="customer__name">Jhon Doe</h5>
                  {/* <p className="description">CEO, Workcreation</p> */}
                </div>
              </div>
            </div>
            <div className="slider__item">
              <p className="description">
              "This Maps review service is a game-changer for any business looking to improve their online reputation. They are professional, responsive, and truly care about their clients' success. I've seen a huge increase in positive reviews on Google Maps since I started working with them, and I'm confident that they can help any business achieve the same level of success."
              </p>
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava02} alt="imageCustomer" />
                </div>

                <div>
                  <h5 className="customer__name">Christina</h5>
                  {/* <p className="description">Software Developer</p> */}
                </div>
              </div>
            </div>
            <div className="slider__item">
              <p className="description">
              "I was initially skeptical about using a Maps review service, but after seeing the results they delivered, I'm a true believer. Not only did they help me get more positive reviews, but they also helped me respond to negative reviews in a professional and effective way. Their team is responsive, professional, and truly cares about helping their clients succeed."
              </p>
              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava03} alt="imageCustomer" />
                </div>

                <div>
                  <h5 className="customer__name">William Cooper</h5>
                  {/* <p className="description">Sr. Product Designer</p> */}
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
