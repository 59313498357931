import React from "react";
import "../../src/styles/services.css";

const serviceData = [
  {
    icon: "ri-apps-line",
    title: "Web Development",
    desc: "Our expert app development services provide a seamless and engaging user experience, delivering custom solutions that cater to your unique business needs and objectives!!",
  },
  {
    icon: "ri-code-s-slash-fill",
    title: "Web Design",
    desc: "Our web design services create stunning and responsive websites that elevate your online presence, attract and retain visitors, and enhance your brand image and reputation.!!",
  },
  {
    icon: "ri-landscape-line",
    title: "Graphics Design",
    desc: "Our graphics design services empower your brand with visually striking and memorable graphics that captivate your audience, communicate your message effectively, and strengthen your brand identity!!",
  },
  {
    icon: "ri-rocket-line",
    title: "Digital Marketing",
    desc: "Our digital marketing services drive measurable and sustainable growth for your business, leveraging the power of data, technology, and creativity to reach and engage your target audience effectively, From search engine optimization (SEO) and pay-per-click (PPC) advertising to social media marketing and email marketing!!",
  },
];

const Skills = () => {
  return (
    <div
      id="services"
      name="service"
      className="w-full h-screen bg-[#0a192f] text-gray-300"
    >
      {/* Container */}
      <div className="container max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="services__top-content">
          <p className="text-4xl font-bold inline border-b-4 border-pink-600 ">
            Our Services
          </p>
          <h2 className="p-4">
            Streamline your operations and scale your business
          </h2>
          <p className="py-4">
            Let our services simplify your business management, so you can work
            smarter, not harder.
          </p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8 service__item-wrapper">
          {serviceData.map((item, index) => (
            <div
              className="services__item shadow-md shadow-[#040c16] hover:scale-110 duration-500"
              key={index}
            >
              <div className="service__icon">
                <i class={item.icon}></i>
              </div>
              <h3 className="service__title">{item.title}</h3>
              <div className="description">{item.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
