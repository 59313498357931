import React from "react";
import "../styles/footer.css";

import {
  FaInstagram,
  FaGithub,
  FaWhatsappSquare,
  FaTelegram,
} from "react-icons/fa";


const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="w-full mt-24 bg-slate-900 text-gray-300 py-y px-2">
      <div className="max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8">
        <div className="col-span-2 pt-8 md:pt-2 ">
          <h2>Reviews Agency</h2>
          <p className="font-bold uppercase mt-3 text-green-600">
            Grow with us
          </p>
          <p className="py-4">
            Our website marketing services are designed to boost your online
            presence, attract high-quality traffic, and convert visitors into
            lifelong customers through a data-driven and result-oriented
            approach.
          </p>
        </div>
        <div>
          <h6 className="font-bold uppercase pt-2">Solutions</h6>
          <ul>
            <li className="py-1">App Development</li>
            <li className="py-1">Web Design</li>
            <li className="py-1">Graphics Design</li>
            <li className="py-1">Digital Marketing</li>
          </ul>
        </div>

        <div>
          <h6 className="font-bold uppercase pt-2" text-green-600>
            Company
          </h6>
          <ul>
            <li className="py-1 ">Home</li>
            <li className="py-1">About</li>
            <li className="py-1">Services</li>
            <li className="py-1">Contact</li>
          </ul>
        </div>
        <div>
          <ul id="footer" className="font-bold uppercase pt-10 ml-5  text-green-600">
            <li className="py-0 "><a href="https://wa.me/message/2K7N5BLXPNZEN1">
            Whatsapp: +1 (507) 626-9787
          </a></li>
          </ul>
          <ul className="font-bold uppercase pt-10 ml-10  text-green-600">
            <li className="py-1">Email: info@ramireviews.com </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="copyright">
          Copyright {year}, developed by{" "}
          <span className="copyright__span">
            <a href="https://wa.me/message/2K7N5BLXPNZEN1">Reviews Agency.</a>
          </span>{" "}
          All rights reserved.{" "}
        </p>
        <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
          <a href="https://www.instagram.com/reviews_agency/?igshid=ZDdkNTZiNTM=">
            <FaInstagram />
          </a>
          <a href="https://github.com/RamiSwa">
            <FaGithub />
          </a>
          <a href="https://wa.me/message/2K7N5BLXPNZEN1">
            <FaWhatsappSquare />
          </a>
          <a href="https://t.me/+_iT1sCmbDXkyYmJk">
            <FaTelegram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
