import React from "react";
import "../../src/styles/home.css";

import heroDarkImg from "../images/hero-min.jpg";
import { Link } from "react-scroll";


const Home = () => {
  return (
    <section className="hero__section" id="home">
      <div className="container">
        <div
          name="home"
          className="w-full h-screen bg-[#0a192f]  hero__wrapper">
          {/* Container */}
          <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
            <h2 className="text-pink-600 mb-3 font-bold sm:text-lg">We specialize in crafting</h2>
            <h2 className="text-xl sm:text-xl font-bold text-[#ccd6f6]">
              exceptional digital solutions that elevate your brand,
            </h2>
            <h2 className="text-xl sm:text-3xl font-bold text-[#8892b0]">
              engage your audience, and drive meaningful results.
            </h2>
            <p className="text-[#8892b0] py-4 max-w-[700px description">
              At our company, we understand that having a stunning website alone
              isn't enough to achieve online success. That's why we offer
              website marketing services that go beyond mere aesthetics and
              focus on delivering tangible results. Our team of digital
              marketing experts works closely with you to understand your unique
              business needs, goals, and target audience to develop a
              comprehensive strategy that enhances your online presence,
              attracts high-quality traffic, and maximizes your conversion
              rates.
              <br /> <br />
              We leverage the latest technologies and tools to analyze your
              website's performance, identify areas of improvement, and optimize
              your website for maximum visibility, engagement, and
              profitability. With our data-driven and result-oriented approach,
              you can rest assured that your website is in good hands, and we're
              committed to delivering exceptional value and ROI to help you
              achieve your business objectives.
              <div className="hero__btns">
                <button className="primary__btn">
                  <Link to="services" smooth={true} duration={500}>
                  Get Started
          </Link>
                </button>
              </div>
            </p>
          </div>
          <div className="hero__img">
            <img src={heroDarkImg} alt="hero" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
