import About from "./components/About";
// import Contact from "./components/Contact";
import Counter from "./components/Counter";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";

import Testimonial from "./components/Testimonial";
import Services from "./components/Services";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <Counter />
      <About />
      <Services />
      <Testimonial />
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default App;
