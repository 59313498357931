import React from "react";
import "../../src/styles/about.css";

import aboutImg from "../../src/images/aboutUs-min.jpg";

const chooseData = [
  {
    icon: "ri-wifi-line",
    title: "In our first work process,",
    desc: "we take the time to understand your business, goals, and target audience, laying the foundation for a customized website development and marketing plan that delivers measurable results.",
  },
  {
    icon: "ri-team-line",
    title: "Committed workforce",
    desc: "At the heart of our success is our unwavering dedication to work, fueled by our passion for delivering exceptional results, exceeding expectations, and creating meaningful connections with our clients that last a lifetime.",
  },
  {
    icon: "ri-customer-service-line",
    title: "24/7 Hours support",
    desc: "Experience peace of mind knowing that our 24/7 support is just a call, email, or chat away, ensuring that you never have to face any technical or operational challenges alone.",
  },
];

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="about__wrapper">
          <div className="about__content">
            <h6 className="subtitle">Why choose us</h6>
            <h2>Specialist in aviding clients on</h2>
            <h2 className="highlight">financial challenges</h2>
            <p className="description about__content-desc">
              Discover what sets us apart from the competition and how we can
              help you achieve your digital marketing goals. When you choose us,
              you're not just getting a service provider, you're gaining a
              strategic partner that is committed to your success.
            </p>

            <div className="choose__item-wrapper">
              {chooseData.map((item, index) => (
                <div className="choose__us-item" key={index}>
                  <span className="choose__us-icon">
                    <i class={item.icon}></i>
                  </span>
                  <div>
                    <h4 className="choose__us-title">{item.title}</h4>
                    <p className="description">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="about__img">
            <img src={aboutImg} alt="aboutImage" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
